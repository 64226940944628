import React from 'react';
import Tabs from 'src/components/ui/tabs/Tabs';
import TabsContent from 'src/components/ui/tabs/TabsContent';
import { SeriesCard } from 'src/components/shared';

import * as Styles from 'src/styles/first-time-user-hero.module.scss';

interface FirstTimeUserTabsProps {
    noEquipmentSeriesList: any[];
    equipmentSeriesList: any[];
}

const FirstTimeUserTabs = ({
    noEquipmentSeriesList,
    equipmentSeriesList,
}: FirstTimeUserTabsProps) => {
    return (
        <Tabs whiteText centerTabs>
            <TabsContent label="No Equipment">
                <div className={Styles.firstTimeUserTabs}>
                    {noEquipmentSeriesList.map((series, index) => {
                        return <SeriesCard series={series} key={`series-card-${index}`} />;
                    })}
                </div>
            </TabsContent>
            <TabsContent label="Equipment">
                <div className={Styles.firstTimeUserTabs}>
                    {equipmentSeriesList.map((series, index) => {
                        return <SeriesCard series={series} key={`series-card-${index}`} />;
                    })}
                </div>
            </TabsContent>
        </Tabs>
    );
};

export default FirstTimeUserTabs;
