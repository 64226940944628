import React from 'react';
import { Link } from 'gatsby';
import { BorderBlockWithButton } from 'src/components/ui';
import { Breakpoints, Directions, useBreakpoints } from 'src/utils/breakpoint-utils';
import { useSelector } from 'react-redux';
import Selectors from '@pvolve/sdk/src/app/selectors';
import RootSelectors from 'src/state/root-selectors';
import * as Styles from 'src/styles/first-time-user-hero.module.scss';
import FirstTimeUserTabs from './FirstTimeUserTabs';
import { isEmpty } from 'lodash';
import useBackgroundImage from 'src/utils/custom-hooks/useBackgroundImage';
import classNames from 'classnames';

const maxSeriesLen = 4;
const notAllowedLen = 3;

const getFeaturedSeries = (featuredSeriesList: string[], allSeries: any[]) => {
    let seriesContentList: any[] = [];

    if (isEmpty(featuredSeriesList) || isEmpty(allSeries)) {
        return seriesContentList;
    }

    let newSeriesIds = [...featuredSeriesList];

    if (newSeriesIds.length === notAllowedLen) {
        newSeriesIds.pop();
    }

    newSeriesIds.forEach((seriesID) => {
        let featuredSeries = allSeries.find((series) => series.contentful_id === seriesID);

        if (featuredSeries && seriesContentList.length < maxSeriesLen) {
            seriesContentList.push(featuredSeries);
        }
    });

    return seriesContentList;
};

const A: React.FC = (props) => <a {...props} />;

interface FirstTimeUserProps {
    header?: string;
    contentText?: string;
    textCTA?: string;
    allSeries: object[];
}

const FirstTimeUserHero = ({ header, contentText, textCTA, allSeries }: FirstTimeUserProps) => {
    const backgroundImageURL = useBackgroundImage('backgroundftu');

    const seriesFeatured = useSelector(Selectors.series.seriesFeatured);

    let noEquipmentSeries =
        seriesFeatured['ftu-myplan-series']?.featuredAttributes['ftu-no-equipment-series']
            ?.seriesIds ?? [];
    let equipmentSeries =
        seriesFeatured['ftu-myplan-series']?.featuredAttributes['ftu-equipment-series']
            ?.seriesIds ?? [];
    let noEquipmentSeriesList = getFeaturedSeries(noEquipmentSeries, allSeries);
    let equipmentSeriesList = getFeaturedSeries(equipmentSeries, allSeries);

    const isTabletPortraitOrBelow = useBreakpoints(Breakpoints.lg, Directions.down);

    const showBetaBanner =
        useSelector(RootSelectors.config.featureFlags)?.showBetaClassesBanner || false;

    return (
        <section>
            <div
                className={classNames(
                    Styles.firstTimeUserContainer,
                    showBetaBanner ? Styles.showBetaBanner : ''
                )}
                style={backgroundImageURL}
            >
                <h1 className={Styles.firstTimeUserHeader}>{header}</h1>
                <p className={Styles.firstTimeUserParagraph}>{contentText}</p>
                <div className={Styles.firstTimeUserTabsContainer}>
                    <FirstTimeUserTabs
                        noEquipmentSeriesList={noEquipmentSeriesList}
                        equipmentSeriesList={equipmentSeriesList}
                    />
                </div>
            </div>
            <div className={Styles.consultationCtaSection}>
                <div className={Styles.borderBlockWrapper}>
                    <BorderBlockWithButton
                        alignment={isTabletPortraitOrBelow ? 'top' : 'left'}
                        iconName="pv-message"
                        iconSize={40}
                        buttonProps={{
                            as: Link,
                            children: <>Book 15 minutes</>,
                            size: 'large',
                            target: '_blank',
                            to: 'https://calendly.com/pvolve/15min',
                        }}
                    >
                        <div className={Styles.consultationTextWrapper}>
                            <p className="p1">{textCTA}</p>
                        </div>
                    </BorderBlockWithButton>
                </div>
            </div>
        </section>
    );
};

FirstTimeUserHero.defaultProps = {
    header: 'Your journey starts here',
    contentText:
        'Kickstart your routine with a series tailored exactly to your goals and equipment.',
    textCTA:
        'Want to personalize your plan? Schedule a free session with a movement specialist to start working toward your goals.',
};

export default FirstTimeUserHero;
