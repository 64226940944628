import React, { useCallback } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ContentSelectors } from '@pvolve/sdk/src/redux/selectors';
import Actions from '@pvolve/sdk/src/app/actions';

// Components
import PvolveIcon from 'src/components/shared/Icon';
import { Button } from 'semantic-ui-react';
import * as Styles from 'src/styles/workout-schedule.module.scss';

interface CongratsSectionProps {
    seriesId: string;
}

const CongratsSection = ({ seriesId }: CongratsSectionProps) => {
    const dispatch = useDispatch();
    const seriesIndex = useSelector(ContentSelectors.series.indexedBy.id);
    const series = seriesIndex[seriesId];

    const unenrollFromSerieClick = useCallback(() => {
        dispatch(Actions.series.leave.trigger({ seriesId }));
    }, [dispatch, seriesId]);

    if (!series) {
        return null;
    }

    return (
        <>
            <div className={Styles.completedContainer}>
                <div className={`${Styles.completeBadgeBox}`}>
                    <PvolveIcon className={`${Styles.completeIcon}`} name="pv-ribbon" size={90} />
                </div>
                <div className={`${Styles.completeCongratsBox} ${Styles.verticalAlignContainer}`}>
                    <div className={`${Styles.verticalAlignMiddle} ${Styles.padRight}`}>
                        <span className={Styles.congratsShort}>Congrats!</span>
                        <p className={Styles.congratsText}>You completed {series?.fields?.name}</p>
                        <Button secondary onClick={unenrollFromSerieClick}>
                            MARK AS COMPLETE
                        </Button>
                    </div>
                </div>
            </div>
            <div className={Styles.congratsSection}>
                <h2 className="upper bold">make your next move</h2>
                <p>
                    Explore more structured programs designed by our trainers to maximize your
                    results.
                </p>
            </div>
        </>
    );
};

export default CongratsSection;
