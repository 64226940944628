export const applyFilter = (contentType: string, items: any[], featureFlag: any) => {
    if (!items) {
        return [];
    }

    if (featureFlag?.phaseAndFunctionBeta?.result) {
        return items;
    }

    const payload = featureFlag?.phaseAndFunctionSeries?.variant?.payload;
    if (!payload) {
        return;
    }
    const types = payload[contentType] || [];

    let resultFilter: any[] = [];
    for (const item of items) {
        if (!!item.node && types?.includes(item.node.contentful_id)) {
            continue;
        } else if (types?.includes(item.contentful_id)) {
            continue;
        }
        resultFilter.push(item);
    }
    return resultFilter;
};

export const checkBetaUserPermit = (featureFlag: any, contentful_id?: string): boolean => {
    if (contentful_id) {
        /* if a contentful_id exists, check beta user and also do a comparison against ID of PnF */
        const betaSeries = featureFlag?.phaseAndFunctionSeries?.variant.payload.series || [];
        if (betaSeries.length > 0 && betaSeries.includes(contentful_id)) {
            if (featureFlag.phaseAndFunctionBeta.result) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    } else {
        /* if it doesn't exist, check beta user */
        if (featureFlag?.phaseAndFunctionBeta?.result) {
            return true;
        } else {
            return false;
        }
    }
};
