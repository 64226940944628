import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { first, isEmpty } from 'lodash';
import { Link, navigate } from 'gatsby';
import { getPreviewVideoSrc } from 'src/utils/sdk-utils';
import * as Styles from 'src/styles/scheduled-workouts.module.scss';
import { GlyphName } from '../shared/pvolveGlyphMap';
import { FavoriteButton, FontOrSvgIcon, VideoPlayerControls } from '../shared';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import { IWorkout } from '@pvolve/sdk/src/models/contentful_types';
import { FavoriteCollectionName, IState } from '@pvolve/sdk/src/redux/selectors';
import { format, parseISO } from 'date-fns';
import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';

const connector = connect((state: IState, { workout }: { workout: IWorkout }) => ({
    workout: PvolveSelectors.content.resolveWorkout(state)(workout?.sys?.id),
}));

const equipmentList = (
    { icon, name, svgIcon }: { icon: GlyphName; name: string; svgIcon?: string },
    index: number
) => {
    return (
        <li key={index} className={Styles.equipmentIconLine}>
            <FontOrSvgIcon icon={icon} svg={svgIcon} />
            <p className={Styles.equipmentName}>{name}</p>
        </li>
    );
};
interface ScheduledWorkoutProps extends ConnectedProps<typeof connector> {
    header: string;
    hasOverlay?: boolean;
    seriesName?: string;
    seriesSlug?: string;
    completePercentage: number;
    paused?: boolean;
    pausedAt?: number;
    phaseData?: object;
    extraContentPath?: string;
}
const ScheduledWorkout = ({
    workout,
    header,
    hasOverlay,
    seriesName,
    seriesSlug,
    completePercentage,
    paused,
    pausedAt,
    phaseData,
    extraContentPath,
}: ScheduledWorkoutProps) => {
    const {
        contentful_id: workoutId,
        slug,
        level,
        name: workoutName,
        description: workoutDescription,
        equipment,
        instructor,
        createdAt,
        thumbnail,
    } = workout;

    const strInstructors = first(instructor)?.name;
    const [loading, setLoading] = useState(true);
    const [previewVideoSrc, setPreviewVideoSrc] = useState<string>();
    const mounted = useRef(false);

    const isTabletAndBelow = useBreakpoints(Breakpoints.xxl, Directions.down);

    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    const formatedCreatedAt = format(parseISO(createdAt), 'MM.dd.yy', new Date());

    useEffect(() => {
        async function loadPreviewVideo() {
            try {
                const { uri } = await getPreviewVideoSrc(workoutId, 'workout');
                if (mounted.current) {
                    if (uri) {
                        setPreviewVideoSrc(uri);
                    }

                    setLoading(false);
                }
            } catch (error) {}
        }

        loadPreviewVideo();
    }, [workoutId]);

    const onVideoClick = useCallback(
        (time?: number) => () => {
            if (time) {
                navigate(`/workouts/${slug}/video?t=${time}`);
            } else {
                navigate(`/workouts/${slug}/video`);
            }
        },
        [slug]
    );

    return (
        <div className={Styles.container}>
            <div className={Styles.videoContainer}>
                <VideoPlayerControls
                    loading={loading}
                    onVideoClick={onVideoClick}
                    paused={paused}
                    previewVideoSrc={previewVideoSrc}
                    slug={slug}
                    hasOverlay={hasOverlay}
                    pausedAt={pausedAt}
                    level={level}
                    seriesName={seriesName}
                    completePercentage={completePercentage}
                    seriesSlug={seriesSlug}
                    thumbnail={thumbnail}
                    phaseData={phaseData}
                    extraContentPath={extraContentPath}
                />
            </div>
            <div className={Styles.detailsContainer}>
                <div>
                    {!isEmpty(header) && (
                        <h1 className={`${Styles.workoutHeader} bold upper`}>{header}</h1>
                    )}
                    <h4 className={`upper bold ${Styles.workoutName}`}>{workoutName}</h4>
                    <p className={`p2 ${Styles.instructorName}`}>
                        with {strInstructors} &bull; {formatedCreatedAt}
                    </p>
                    {isTabletAndBelow ? (
                        <Link className={`${Styles.linkMore} bold upper`} to={`/workouts/${slug}`}>
                            See More
                        </Link>
                    ) : workoutDescription && workoutDescription.length > 200 ? (
                        <p className={`${Styles.workoutDescription} p2`}>
                            {workoutDescription}
                            <Link
                                className={`${Styles.linkMore} ${Styles.seeMore} bold upper`}
                                to={`/workouts/${slug}`}
                            >
                                See More
                            </Link>
                        </p>
                    ) : (
                        <p>{workoutDescription}</p>
                    )}
                </div>
                <div>
                    <ul className={Styles.equipmentList}>
                        {!isEmpty(equipment) && equipment?.map(equipmentList)}
                    </ul>

                    <div className={Styles.favIconLine}>
                        <FavoriteButton
                            collection={FavoriteCollectionName.workouts}
                            id={workoutId}
                            size={35}
                            showLabel
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default connector(ScheduledWorkout);
