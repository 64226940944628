import React, { useCallback, useState } from 'react';
import Img from 'gatsby-image';
import { format, isFuture, isToday, parseISO } from 'date-fns';
import { ConnectedProps, connect } from 'react-redux';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import Actions from 'src/state/root-actions';

import * as Styles from 'src/styles/workout-schedule.module.scss';
import { IState, IWorkoutTypes } from '@pvolve/sdk/src/redux/selectors';
import { IEnrollmentItem } from '@pvolve/sdk/src/redux/selectors';
import { Button } from 'semantic-ui-react';
import { IWorkout } from '@pvolve/sdk/src/models/contentful_types';
import { ResolvedWorkout } from '@pvolve/sdk/src/app/modules/content/selectors';
import { dayLabel } from '@pvolve/sdk/src/app/utils';
import { ExtraContent } from 'src/components/shared';

const connector = connect((state: IState, { workout }: { workout: IWorkout }) => ({
    workout: PvolveSelectors.content.resolveWorkout(state)(workout?.sys?.id),
}));

interface RestOrWalkDayProps extends ConnectedProps<typeof connector> {
    date: Date;
    currentSeries?: IEnrollmentItem;
    seriesName: string;
    workout: ResolvedWorkout;
    lastCompletedIndex: number;
    phaseData?: object;
    extraContentPath?: string;
}

const RestOrWalkDay = ({
    dispatch,
    currentSeries,
    seriesName,
    date,
    workout,
    lastCompletedIndex,
    phaseData,
    extraContentPath,
}: RestOrWalkDayProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const dayType = workout?.type;

    const completePercentage = Math.round((currentSeries?.percentage_completed || 0) * 100);

    const yearFormat = 'yy';
    const formattedDate = format(parseISO(new Date(date).toISOString()), `M.d.${yearFormat}`);
    const dayLabelPrefix = isToday(date)
        ? 'Today is'
        : isFuture(date)
            ? `${dayLabel(date, currentSeries != null).replace(/'s/, '')} is`
            : `${formattedDate} was`;

    const header = `${dayLabel(date, currentSeries != null)} Workout`;

    const onWalkComplete = useCallback(() => {
        if (workout) {
            setLoading(true);

            const onSuccess = () => {
                setTimeout(() => {
                    dispatch(Actions.workouts.loadHistory.trigger());
                    setLoading(false);
                }, 1000);
            };

            dispatch(
                Actions.workouts.complete.trigger({ workout_id: workout.contentful_id, onSuccess })
            );
        }
    }, [dispatch, workout]);

    return (
        <div className={Styles.noCompletedContainer}>
            <div className={Styles.background} />

            <div className={`padding--0 ${Styles.completedInnerBox}`}>
                {dayType === IWorkoutTypes.rest ? (
                    <>
                        <div className={Styles.restImage}>
                            <Img
                                style={{ height: '100%', width: '100%' }}
                                fixed={workout?.thumbnail?.fixed}
                                alt="rest day image"
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className={Styles.walkImage}>
                            <Img
                                style={{ height: '100%', width: '100%' }}
                                fixed={workout?.thumbnail?.fixed}
                                alt="walk day image"
                            />
                        </div>
                    </>
                )}
                <div className={`${Styles.completedBottomBand} ${Styles.walkRestDay} margin--0`}>
                    {!!extraContentPath ? (
                        <ExtraContent
                            template={extraContentPath}
                            seriesName={seriesName}
                            location={'slide'}
                            phaseData={phaseData}
                        />
                    ) : (
                        <>
                            <div className={Styles.seriesTag}>
                                <div className={Styles.seriesName}>{seriesName}</div>
                                <span className={Styles.workoutProgressText}>
                                    {completePercentage}% complete
                                </span>
                            </div>
                            <div
                                style={{ width: `${completePercentage}%` }}
                                className={`${Styles.percentageBar} ${Styles.rwBar}`}
                            />
                        </>
                    )}
                </div>
            </div>

            <div className={Styles.completedSummary}>
                {dayType === IWorkoutTypes.rest ? (
                    <>
                        <div className={Styles.noCompletedTitle}>{dayLabelPrefix} A REST DAY</div>

                        <div className={Styles.restText}>
                            {isFuture(date) ? (
                                <p className="accent">{workout.description}</p>
                            ) : (
                                lastCompletedIndex !== -1 && (
                                    <p className="accent">
                                        Recover and reboot before your next sweat.
                                    </p>
                                )
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className={Styles.noCompletedTitle}>{header}</div>

                        <div className={Styles.restText}>
                            <Button loading={loading} onClick={onWalkComplete}>
                                20 min walk complete
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default connector(RestOrWalkDay);
