import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';

import MyPlanPage from 'src/components/my-plan/MyPlanPage';

import { linkToExternal } from 'src/utils/url-utils';
import { ZELDA_HOME_LINK } from 'src/constants/url-constants';

interface MyPlanPhaseFunctionProps {
    data: any;
    path: string;
}

const MyPlanPhaseFunction = (props: MyPlanPhaseFunctionProps) => {
    const currentSeries = useSelector(PvolveSelectors.series.enrollment.current);
    const PHASE_AND_FUNCTION_ID = '5mKnc2WqZShGnUiHG9xB8p';

    const isPhaseFunctionEnrolled = currentSeries?.series_id == PHASE_AND_FUNCTION_ID;

    useEffect(() => {
        if (currentSeries && !isPhaseFunctionEnrolled) {
            linkToExternal(ZELDA_HOME_LINK);
        }
    }, [currentSeries, isPhaseFunctionEnrolled]);

    return isPhaseFunctionEnrolled ? <MyPlanPage {...props} /> : null;
};

export default MyPlanPhaseFunction;
