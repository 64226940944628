import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import { graphql, navigate } from 'gatsby';
import PrivateRoute from 'src/components/PrivateRoute';
import MyPlanPhaseFunction from 'src/components/my-plan/MyPlanPhaseFunction';
import { useSelector } from 'react-redux';
import TransitionPage from 'src/components/shared/TransitionPage';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import Selectors from 'src/state/root-selectors';
import { ZELDA_HOME_LINK, MY_PLAN_PNF_PATH } from 'src/constants/url-constants';
import { linkToExternal } from 'src/utils/url-utils';

const MyPlan = ({ data, location }) => {
    const currentSeries = useSelector(PvolveSelectors.series.enrollment.current);
    const loadingFinished = useSelector(PvolveSelectors.series.enrollment.enrollLoadingFinished);
    const loggedIn = useSelector(Selectors.auth.loggedIn);
    const PHASE_AND_FUNCTION_ID = '5mKnc2WqZShGnUiHG9xB8p';

    const myPlanProps = {
        data,
    };
    const prevPath = location?.state?.prevPath;

    if (prevPath) {
        myPlanProps.prevPath = prevPath;
        myPlanProps.replace = true;
    }

    const isPhaseFunctionEnrolled = currentSeries?.series_id == PHASE_AND_FUNCTION_ID;

    useEffect(() => {
        if (loggedIn) {
            if (loadingFinished) {
                if (currentSeries) {
                    if (!isPhaseFunctionEnrolled) {
                        linkToExternal(ZELDA_HOME_LINK);
                    } else {
                        navigate(MY_PLAN_PNF_PATH);
                    }
                } else {
                    linkToExternal(ZELDA_HOME_LINK);
                }
            }
        } else {
            navigate('/continue');
        }
    }, [currentSeries, isPhaseFunctionEnrolled, loadingFinished, loggedIn]);

    return (
        <>
            <Router>
                <PrivateRoute
                    path="/my-plan/phase-function"
                    {...myPlanProps}
                    component={MyPlanPhaseFunction}
                />
            </Router>
            <TransitionPage />
        </>
    );
};

export const query = graphql`
    query WorkoutPageQuerys {
        allContentfulSeries {
            edges {
                node {
                    contentful_id
                    ...ContentfulSeries_Card
                }
            }
        }
    }
`;

export default MyPlan;
