import React, { useEffect, useState } from 'react';

// Redux & Contexts
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'src/state/root-actions';
import Selectors from 'src/state/root-selectors';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import { useIsFTU } from 'src/components/my-plan/MyPlanContext';

// Components
import { Spinner } from 'react-activity';
import { SeriesCarousel } from 'src/components/shared';
import FirstTimeUserHero from 'src/components/my-plan/FTU/FirstTimeUserHero';
import WorkoutScheduleCarousel from 'src/components/my-plan/WorkoutScheduleCarousel';
import CongratsSection from 'src/components/my-plan/CongratsSection';
import * as WorkoutStateStyle from 'src/styles/workout-schedule.module.scss';

// Utils
import { applyFilter } from 'src/utils/featureFlag';
import { flattenGraphQLEdges } from 'src/utils/data-utils';
import { getDynamicContent } from 'src/utils/sdk-utils';

interface MyPlanHeroProps {
    allContentfulSeries: any;
    extraContentPath?: any;
}

const MyPlanHero = ({ allContentfulSeries, extraContentPath }: MyPlanHeroProps) => {
    const dispatch = useDispatch();
    const [ftuContentState, setFTUContentState] = useState<any[]>([]);

    const currentSeries = useSelector(PvolveSelectors.series.enrollment.current);
    const experienceLoading = useSelector(Selectors.account.loading);
    const isFTU = useIsFTU();
    const variants = useSelector(Selectors.flags.phaseAndFunction);

    const allSeriesContent = flattenGraphQLEdges(allContentfulSeries);
    const completePercentage = currentSeries?.percentage_completed;

    useEffect(() => {
        async function getFTUTextContent() {
            try {
                const content = await getDynamicContent('FTU.TextContent');
                if (content && content.length > 0) {
                    setFTUContentState(content);
                }
            } catch (err) {
                setFTUContentState([]);
            }
        }

        if (isFTU) {
            getFTUTextContent();
            dispatch(Actions.series.seriesFeatured.trigger());
        }
    }, [dispatch, isFTU]);

    if (experienceLoading) {
        return (
            <div className={WorkoutStateStyle.loader}>
                <Spinner size={40} speed={0.75} />
            </div>
        );
    }

    const activeSeriesCategory = {
        name: 'All Series',
        slug: 'all-series',
        series: applyFilter('series', allContentfulSeries, variants),
    };

    if (isFTU) {
        let ftuText = {
            header: undefined,
            subheader: undefined,
            ctaText: undefined,
        };
        if (ftuContentState && ftuContentState.length > 0) {
            ftuText.header = ftuContentState[0].value?.header;
            ftuText.subheader = ftuContentState[0].value?.subHeader;
            ftuText.ctaText = ftuContentState[0].value?.ctaText;
        }

        return (
            <FirstTimeUserHero
                header={ftuText.header}
                contentText={ftuText.subheader}
                allSeries={allSeriesContent}
                textCTA={ftuText.ctaText}
            />
        );
    } else if (currentSeries && completePercentage === 1) {
        return (
            <>
                {currentSeries.series_id && <CongratsSection seriesId={currentSeries.series_id} />}
                <div className={WorkoutStateStyle.pageSection}>
                    <SeriesCarousel allSeriesCategory={activeSeriesCategory} showMenu />
                </div>
            </>
        );
    }

    return (
        <WorkoutScheduleCarousel
            currentSeries={currentSeries}
            extraContentPath={!!extraContentPath && extraContentPath['WorkoutSchedule']}
        />
    );
};

export default MyPlanHero;
