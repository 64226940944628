import { isEmpty } from 'lodash';

export const getExtraContentPath = (src: any[], node: any[]) => {
    let result: { [key: string]: any } = {};
    for (let s of src) {
        let value: string = node
            .find((e) => RegExp(s, 'gm').test(e))
            ?.split('.')
            .splice(1)
            .join('/');
        if (!!value && value.length != 0) {
            result[s] = value;
        } else {
            continue;
        }
    }
    return isEmpty(result) ? null : result;
};
