import { differenceInCalendarDays } from 'date-fns';
interface phaseLength {
    name: string;
    start: { day: string };
    end: { day: string };
}

export const phasesDefaultState = [
    {
        days: 4,
        name: 'menstrual',
        start: { day: '1' },
        end: { day: '4' },
    },
    {
        days: 8,
        name: 'follicular',
        start: { day: '5' },
        end: { day: '13' },
    },
    {
        days: 2,
        name: 'ovulatory',
        start: { day: '14' },
        end: { day: '16' },
    },
    {
        days: 11,
        name: 'luteal',
        start: { day: '17' },
        end: { day: '28' },
    },
];

export const getPhaseLengthData = (
    phaseName: string,
    phases: Array<phaseLength>,
    isEnrolled: boolean
) => {
    let phase: phaseLength | undefined;

    if (isEnrolled) {
        phase = phases?.find((phaseItem: phaseLength) => phaseItem.name === phaseName);

        return {
            start: phase?.start.day,
            end: phase?.end.day,
        };
    }

    phase = phasesDefaultState?.find((phaseItem) => phaseItem.name === phaseName);

    return {
        start: phase?.start.day,
        end: phase?.end.day,
    };
};

export const getPastSlideData = (date: Date, phaseAttributes: object | undefined) => {
    if (!phaseAttributes) {
        return;
    }

    let pastSlideData = { pastSlideDay: 0, pastSlidePhase: '' };
    const { cycle, currentDay } = phaseAttributes;
    const daysOfCycle = Number(cycle);
    const differenceInDays = currentDay - differenceInCalendarDays(new Date(), date);

    let pastDay = ((differenceInDays % daysOfCycle) + daysOfCycle) % daysOfCycle;

    pastSlideData.pastSlideDay = pastDay === 0 ? daysOfCycle : pastDay;
    pastSlideData.pastSlidePhase = getCurrentPhase(pastDay, phaseAttributes.phases);

    return pastSlideData;
};

export const getCurrentPhase = (cycleDay: number, phases: []) => {
    if (!!phases && phases.length > 0) {
        if (cycleDay < phases[1].start.day) return phases[0].name;

        if (phases[1].start.day <= cycleDay && cycleDay < phases[2].start.day)
            return phases[1].name;

        if (phases[2].start.day <= cycleDay && cycleDay < phases[3].start.day)
            return phases[2].name;

        if (cycleDay >= phases[3].start.day) return phases[3].name;

        return '';
    }
};
