import React from 'react';
import { differenceInCalendarDays, format, isToday, isYesterday } from 'date-fns';
import { Link } from 'gatsby';
import { connect, ConnectedProps } from 'react-redux';
import { IEnrollmentItem, IWorkoutHistoryItem } from '@pvolve/sdk/src/redux/selectors';
import { dayLabel, formatInternalDate } from '@pvolve/sdk/src/app/utils';
import { ContentSelectors, IState } from '@pvolve/sdk/src/redux/selectors';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';

import ScheduledWorkout from 'src/components/my-plan/ScheduledWorkout';
import TinyListCard from 'src/components/shared/TinyListCard';
import { CircleCheckmark, LinkWithArrow, ExtraContent } from 'src/components/shared';

import * as Styles from 'src/styles/workout-schedule.module.scss';
import RestOrWalkDay from './RestOrWalkDay';
import ButtonLink from 'src/components/shared/ButtonLink';

import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';
import Colors from 'src/utils/colors';

interface WorkoutScheduleSlideProps {
    active: boolean;
    currentSeries?: IEnrollmentItem;
    currentWorkout: IWorkoutHistoryItem;
    date: Date;
    lastCompletedIndex: number;
    goToLastCompletedSlide: any;
    extraContentPath?: string;
    pastSlideData: { pastSlideDay: number; pastSlidePhase: string } | undefined;
}

const connector = connect((state: IState) => ({
    workoutIndex: ContentSelectors.workout.indexedBy.id(state),
    seriesIndex: ContentSelectors.series.indexedBy.id(state),
    completedHistoryByDate: PvolveSelectors.workouts.completedHistoryByDate(state),
    nextWorkoutWhenNotEnrolled: PvolveSelectors.workouts.nextWorkoutWhenNotEnrolled(state),
}));

const WorkoutScheduleSlide = ({
    active,
    currentSeries,
    currentWorkout,
    date,
    lastCompletedIndex,
    goToLastCompletedSlide,
    workoutIndex,
    seriesIndex,
    completedHistoryByDate,
    nextWorkoutWhenNotEnrolled,
    extraContentPath,
    pastSlideData,
}: WorkoutScheduleSlideProps & ConnectedProps<typeof connector>) => {
    const completedPageDateFormat = format(date, 'M-dd-yyyy');
    const series = seriesIndex[currentSeries ? currentSeries?.series_id : 0];
    const seriesName = series?.fields.name;
    const seriesSlug = series?.fields.slug;
    const hasOverlay = !isToday(date);

    const isDesktop = useBreakpoints(Breakpoints.sm, Directions.up);

    const dayTitle = isToday(date)
        ? 'Today'
        : isYesterday(date)
            ? 'Yesterday'
            : `${differenceInCalendarDays(new Date(), date)} days ago`;
    const completedWorkouts = Object.values(completedHistoryByDate[formatInternalDate(date)] || []);
    const completePercentage = Math.round((currentSeries?.percentage_completed || 0) * 100);
    const header = `${dayLabel(date, series != null)} Workout`;
    const classes = `bold upper display-flex align-items--center link-with-arrow pv-icon pv-arrow-left`;
    const workout = workoutIndex[currentWorkout?.workout_id];
    const phaseData = {
        cycleDay: currentWorkout?.cycleDay || pastSlideData?.pastSlideDay,
        phase: currentWorkout?.phase || pastSlideData?.pastSlidePhase,
    };

    if (!active) {
        return null;
    }

    if (workout) {
        // I have workout that is not completed so allow the user to start
        const dayType = workout?.fields?.type;

        if (dayType) {
            return (
                <RestOrWalkDay
                    currentSeries={currentSeries}
                    seriesName={seriesName}
                    workout={workout}
                    date={date}
                    lastCompletedIndex={lastCompletedIndex}
                    phaseData={phaseData}
                    extraContentPath={extraContentPath}
                />
            );
        }

        if (currentWorkout.total_seconds > 0) {
            // I have workout history so allow the user to resume
            const pausedAt = currentWorkout.total_seconds;
            return (
                <ScheduledWorkout
                    workout={workout}
                    header={header}
                    seriesName={seriesName}
                    completePercentage={completePercentage}
                    paused
                    pausedAt={pausedAt}
                    phaseData={phaseData}
                    extraContentPath={extraContentPath}
                />
            );
        }

        return (
            <ScheduledWorkout
                workout={workout}
                header={header}
                hasOverlay={hasOverlay}
                seriesName={seriesName}
                seriesSlug={seriesSlug}
                completePercentage={completePercentage}
                phaseData={phaseData}
                extraContentPath={extraContentPath}
            />
        );
    } else if (completedWorkouts.length > 0) {
        // I completed at least one workout on this day so show the list of completed
        return (
            <div className={Styles.noCompletedContainer}>
                <div className={Styles.background} />
                <div className={Styles.completedInnerBox}>
                    <div className={Styles.innerBoxWrapper}>
                        <div className={Styles.circle}>
                            <CircleCheckmark
                                checkColor={'desert'}
                                circleBgColor={Colors.black}
                                size={isDesktop ? 50 : 27}
                            />
                        </div>
                        <div className={Styles.completedMiddleText}>Workout Complete</div>
                        <div className={Styles.congratsShort}>Way to get it done!</div>
                    </div>
                    {seriesName && (
                        <Link to={`/series/${seriesSlug}`}>
                            <div className={Styles.completedBottomBand}>
                                {!!extraContentPath ? (
                                    <ExtraContent
                                        template={extraContentPath}
                                        seriesName={seriesName}
                                        location={'slide'}
                                        phaseData={phaseData}
                                    />
                                ) : (
                                    <>
                                        <div className={Styles.seriesTag}>
                                            <div className={Styles.seriesName}>{seriesName}</div>
                                            <span className={Styles.workoutProgressText}>
                                                {`${completePercentage}% complete`}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                width: `${completePercentage}%`,
                                            }}
                                            className={Styles.percentageBar}
                                        />
                                    </>
                                )}
                            </div>
                        </Link>
                    )}
                </div>
                <div className={Styles.completedSummary}>
                    <div className={Styles.noCompletedTitle}>Completed {dayTitle}</div>

                    <div>
                        <div className={Styles.tinyCards}>
                            {completedWorkouts.map(
                                (complete, index) =>
                                    index <= 1 && (
                                        <div key={index} className={Styles.marginBottom}>
                                            <TinyListCard workout_id={complete.workout_id} />
                                        </div>
                                    )
                            )}
                        </div>
                        {completedWorkouts?.length > 2 && (
                            <LinkWithArrow
                                to={`/workouts/completed/${completedPageDateFormat}`}
                                label={`SEE ALL ${completedWorkouts.length}`}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    } else {
        if (isToday(date) && nextWorkoutWhenNotEnrolled) {
            // I have no workout history and have completed nothing today
            return (
                <ScheduledWorkout
                    workout={workoutIndex[nextWorkoutWhenNotEnrolled.sys.id]}
                    header={header}
                    hasOverlay={hasOverlay}
                    seriesName={seriesName}
                    seriesSlug={seriesSlug}
                    completePercentage={completePercentage}
                    phaseData={phaseData}
                    extraContentPath={extraContentPath}
                />
            );
        }
        // I have no workout history and have completed nothing
        return (
            <div className={Styles.noCompletedContainer}>
                <div className={Styles.background} />

                <div className={Styles.noCompletedInnerBlueBox}>
                    <div className={Styles.innerBoxWrapper}>
                        <div className={Styles.noCompletedTopRing}></div>
                        <div className={Styles.noCompletedMiddleText}>No workouts completed</div>
                    </div>
                    <div className={Styles.noCompletedBottomBand}></div>
                </div>

                <div className={Styles.noCompletedRightContent}>
                    <div className={Styles.noCompletedTitle}>Completed {dayTitle}</div>

                    {lastCompletedIndex !== -1 ? (
                        <div className={Styles.noCompletedLink}>
                            <ButtonLink className={classes} onClick={goToLastCompletedSlide}>
                                jump to last completed workout
                            </ButtonLink>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        );
    }
};

export default connector(WorkoutScheduleSlide);
