import React, { useEffect } from 'react';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';

import { find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import SEO from 'src/components/SEO';
import ThisWeek from 'src/components/my-plan/ThisWeek';
import PromoModuleTop from 'src/components/my-plan/PromoModuleTop';
import PromoModuleBottom from 'src/components/my-plan/PromoModuleBottom';
import Selectors from 'src/state/root-selectors';
import FavoritesWorkout from 'src/components/my-plan/FavoritesWorkout';
import Actions from 'src/state/root-actions';
import { ExtraContent } from 'src/components/shared';
import { getExtraContentPath } from 'src/utils/extra-content';
import Layout from 'src/components/layout/Layout';
import MyPlanHero from 'src/components/my-plan/MyPlanHero';

import * as Styles from 'src/styles/my-plan.module.scss';
import classNames from 'classnames';
import { MyPlanProvider } from 'src/components/my-plan/MyPlanContext';
import useShouldRedirectToFeedback from 'src/components/questionnairev2/hooks/useShouldRedirectToFeedback';
import {
    QUESTIONNAIRE_CATEGORY,
    QUESTIONNAIRE_SUBCATEGORY,
} from '@pvolve/sdk/src/app/modules/questionnaire/constants';
import { FREE_TRIAL_LINK, UPGRADE_PLAN_LINK } from 'src/constants/url-constants';
import { navigate } from 'gatsby';
interface MyPlanPageProps {
    data: any;
    path: string;
}

const MyPlanPage = ({ data, path }: MyPlanPageProps) => {
    const dispatch = useDispatch();

    const currentSeries = useSelector(PvolveSelectors.series.enrollment.current);
    const isFTU = useSelector(Selectors.auth.isFTUWeb)
        ? true
        : path == '/my-plan/ftu'
            ? true
            : false;
    const entitled = useSelector(Selectors.auth.entitled);
    const hasSubscriptionHistory = useSelector(Selectors.subscriptions.hasSubscriptionHistory);
    const loadingSubscription = useSelector(Selectors.subscriptions.loading);

    /**
     * My Plan is a logged-in page.
     * If not logged in, redirect to How it Works Page.
     *
     */
    const shouldredirectToFeedbackSurvey = useShouldRedirectToFeedback();
    const shouldRedirectToStream = window && !entitled;
    const shouldRedirect = shouldRedirectToStream || shouldredirectToFeedbackSurvey;

    useEffect(() => {
        if (shouldredirectToFeedbackSurvey) {
            navigate(
                `/questionnaires/${QUESTIONNAIRE_CATEGORY?.feedback}/${QUESTIONNAIRE_SUBCATEGORY.series}`
            );
        }
    }, [shouldredirectToFeedbackSurvey]);

    useEffect(() => {
        if (shouldRedirectToStream && !loadingSubscription) {
            if (hasSubscriptionHistory) {
                navigate(UPGRADE_PLAN_LINK);
            } else {
                navigate(FREE_TRIAL_LINK);
            }
        }
    }, [shouldRedirectToStream, loadingSubscription]);

    useEffect(() => {
        if (entitled) {
            dispatch(Actions.workouts.loadHistory.trigger());
            dispatch(Actions.workouts.loadStats.trigger());
            dispatch(Actions.account.getAllAttrs.trigger());
            dispatch(Actions.series.enrolled.trigger());
        }
    }, [dispatch, entitled, isFTU]);

    // In the case that we will redirect, the render method should just return null
    if (shouldRedirect) {
        return null;
    }

    const allContentfulSeries = data?.allContentfulSeries?.edges;

    const contentfulSeries = find(allContentfulSeries, {
        node: { contentful_id: currentSeries?.series_id },
    });

    const extraContentNode =
        !!contentfulSeries && !!currentSeries?.series_id && contentfulSeries?.node.extraContent;

    const extraContentPath =
        !!extraContentNode && getExtraContentPath(['Myplan', 'WorkoutSchedule'], extraContentNode);

    const pageContext = {
        theme: {
            headerBgColor: isFTU ? 'black' : '',
        },
    };

    return (
        <MyPlanProvider isFTU={isFTU}>
            <Layout pageContext={pageContext}>
                <SEO title="Workouts: My Plan" />
                <div className={isFTU ? '' : classNames(Styles.pageSection, Styles.heroSection)}>
                    <MyPlanHero
                        allContentfulSeries={allContentfulSeries}
                        extraContentPath={extraContentPath}
                    />
                </div>
                {!!extraContentPath && <ExtraContent template={extraContentPath['Myplan']} />}
                <PromoModuleTop />
                <div className={Styles.pageSection}>
                    <ThisWeek />
                </div>
                <PromoModuleBottom />
                <FavoritesWorkout />
            </Layout>
        </MyPlanProvider>
    );
};

export default MyPlanPage;
