// extracted by mini-css-extract-plugin
export const wrapper = "workout-schedule-module--wrapper--2LfGz";
export const providerCarouselWrapper = "workout-schedule-module--provider-carousel-wrapper--26iaS";
export const phaseTag = "workout-schedule-module--phase-tag--3nj8D";
export const noCompletedContainer = "workout-schedule-module--no-completed-container--UAm9O";
export const background = "workout-schedule-module--background--3lGto";
export const completedContainer = "workout-schedule-module--completed-container--2-914";
export const noCompletedInnerBlueBox = "workout-schedule-module--no-completed-inner-blue-box--23bwu";
export const noCompletedRightContent = "workout-schedule-module--no-completed-right-content--1yZ8e";
export const completedInnerBox = "workout-schedule-module--completed-inner-box--x-sZ5";
export const innerBoxWrapper = "workout-schedule-module--inner-box-wrapper--2ghTO";
export const restInnerBox = "workout-schedule-module--rest-inner-box--3O2hb";
export const noCompletedTitle = "workout-schedule-module--no-completed-title--3M6dj";
export const restTitle = "workout-schedule-module--rest-title--16NFs";
export const restImage = "workout-schedule-module--rest-image--zUVKG";
export const walkImage = "workout-schedule-module--walk-image--XNM3C";
export const restText = "workout-schedule-module--rest-text--3zrq1";
export const walkText = "workout-schedule-module--walk-text--1Y6qK";
export const restDayButtonLink = "workout-schedule-module--rest-day-button-link--7CPed";
export const walkDescription = "workout-schedule-module--walk-description--1w7Lf";
export const noCompletedMiddleText = "workout-schedule-module--no-completed-middle-text--2XTU_";
export const completedMiddleText = "workout-schedule-module--completed-middle-text--1uWXu";
export const restMiddleText = "workout-schedule-module--rest-middle-text--39JXU";
export const noCompletedTopRing = "workout-schedule-module--no-completed-top-ring--cRpPH";
export const completedTopRing = "workout-schedule-module--completed-top-ring--2gif3";
export const noCompletedLink = "workout-schedule-module--no-completed-link--2q-xG";
export const noCompletedBottomBand = "workout-schedule-module--no-completed-bottom-band--1aApy";
export const completedBottomBand = "workout-schedule-module--completed-bottom-band--14U2i";
export const walkRestDay = "workout-schedule-module--walk-rest-day--1zuwe";
export const seriesTag = "workout-schedule-module--series-tag--1dpCI";
export const completeBadgeBox = "workout-schedule-module--complete-badge-box--196Sq";
export const completeIcon = "workout-schedule-module--complete-icon--CTqT4";
export const completeCongratsBox = "workout-schedule-module--complete-congrats-box--2oDPz";
export const verticalAlignContainer = "workout-schedule-module--vertical-align-container--2D1wQ";
export const fitParent = "workout-schedule-module--fit-parent--16niB";
export const verticalAlignMiddle = "workout-schedule-module--vertical-align-middle--IChtY";
export const padRight = "workout-schedule-module--pad-right--2Niuh";
export const congratsText = "workout-schedule-module--congrats-text--CU1BG";
export const congratsSection = "workout-schedule-module--congrats-section--1wliG";
export const congratsSubtitle = "workout-schedule-module--congrats-subtitle--1B1xK";
export const congratsShort = "workout-schedule-module--congrats-short--1vUEW";
export const seriesName = "workout-schedule-module--series-name--1magp";
export const workoutProgressText = "workout-schedule-module--workout-progress-text--1PI9S";
export const loader = "workout-schedule-module--loader--1mGUB";
export const pageSection = "workout-schedule-module--page-section--3cpNp";
export const marginBottom = "workout-schedule-module--margin-bottom--25SGg";
export const backDrop = "workout-schedule-module--back-drop--3lDYa";
export const favoritesSpacing = "workout-schedule-module--favorites-spacing--10CZC";
export const percentageBar = "workout-schedule-module--percentage-bar--2_cDO";
export const rwBar = "workout-schedule-module--rw-bar--3yamz";
export const completedSummary = "workout-schedule-module--completed-summary--2uspq";
export const tinyCards = "workout-schedule-module--tiny-cards--2e-Gb";
export const circle = "workout-schedule-module--circle--1E_4-";
export const slideElement = "workout-schedule-module--slide-element--1V8Ei";