import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import LinkWithPrevPath from 'src/components/LinkWithPrevPath';
import { Icon, LinkWithArrow, FavoriteButton } from 'src/components/shared';
import { useSaveUrl } from '../../utils/url-utils';
import * as Styles from 'src/styles/tinyListCard.module.scss';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import { FavoriteCollectionName, IState } from '@pvolve/sdk/src/redux/selectors';
import LinkWrapper from './LinkWrapper';

export interface TinyListCardProps {
    workout_id: string;
}

const connector = connect((state: IState, { workout_id }: TinyListCardProps) => {
    return {
        workout: PvolveSelectors.content.resolveWorkout(state)(workout_id),
    };
});

const TinyListCard = ({ workout }: ConnectedProps<typeof connector>) => {
    const { contentful_id, thumbnail, name, slug } = workout;
    const saveUrl: () => void = useSaveUrl('initialUrl');

    const cardImage =
        thumbnail && thumbnail.fixed && thumbnail.fixed.srcWebp
            ? `https:${thumbnail.fixed.srcWebp}`
            : 'https://place-hold.it/300x150&text=Pvolve';

    const isWalkDay = workout?.video == null;

    return (
        <div key={`tiny-list-${workout.slug}`} className={Styles.cardWrapper}>
            <div className={Styles.listImage} style={{ backgroundImage: `url(${cardImage})` }}>
                <div className={Styles.listImageOverlay} />
                {!isWalkDay && (
                    <LinkWithPrevPath
                        className={Styles.listImageLink}
                        to={`/workouts/${slug}/video?t=0`}
                        onClick={saveUrl}
                    >
                        <Icon
                            name="pv-replay"
                            color="black"
                            className={Styles.listImageIcon}
                            size={18}
                        />
                    </LinkWithPrevPath>
                )}
            </div>
            {name && (
                <LinkWrapper
                    isRestOrWalkDay={isWalkDay}
                    props={{
                        to: `/workouts/${slug}`,
                        className: `h5 bold upper color-pv-black margin--0`,
                    }}
                    className={`h5 bold upper color-pv-black margin--0`}
                >
                    {name}
                </LinkWrapper>
            )}
            {!isWalkDay && (
                <div className={Styles.favIcon}>
                    <FavoriteButton
                        collection={FavoriteCollectionName.workouts}
                        id={contentful_id}
                    />
                </div>
            )}
        </div>
    );
};

export default connector(TinyListCard);

export const LastCard = ({ to, length }: { to: string; length: number }) => (
    <div className={Styles.lastCard}>
        <LinkWithArrow to={to} label={`See all ${length}`} />
    </div>
);
