import React, { useEffect, useState } from 'react';
import { getAssetsByTag } from '../sdk-utils';

/**
 * Function that retrieves a background image asset from contentful using a tag
 * @param { string } tag to be searched
 * @returns { React.CSSProperties } CSSProperties object including background-image with URL from the image from contentful
 */
const useBackgroundImage = (tag: string): React.CSSProperties => {
    const [backgroundImage, setBackgroundImage] = useState<React.CSSProperties>({});

    useEffect(() => {
        const getBackgroundImg = async () => {
            const assetsLists = await getAssetsByTag(tag);
            if (assetsLists?.length > 0) {
                const imageUrl = assetsLists[0]?.file?.url;
                if (imageUrl) {
                    setBackgroundImage({ backgroundImage: `url(${imageUrl})` });
                }
            }
        };

        getBackgroundImg();
    }, [tag]);

    return backgroundImage;
};

export default useBackgroundImage;
