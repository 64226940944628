import React, { useRef } from 'react';
import classNames from 'classnames';

import Icon from 'src/components/shared/Icon';
import { Popup, StrictPopupProps } from 'semantic-ui-react';
import { addDays, isToday, format } from 'date-fns';
import { startOfWeek, isBefore } from 'date-fns/esm';
import { round, times } from 'lodash';
import { formatInternalDate, parseDate } from '@pvolve/sdk/src/app/utils';
import TinyListCard, { LastCard } from 'src/components/shared/TinyListCard';
import { ContentSelectors, IState } from '@pvolve/sdk/src/redux/selectors';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import { connect, ConnectedProps } from 'react-redux';
import * as Styles from 'src/styles/this-week.module.scss';
import { Breakpoints, Directions, useBreakpoints } from 'src/utils/breakpoint-utils';

const connector = connect((state: IState) => ({
    workoutIndex: ContentSelectors.workout.indexedBy.id(state),
    stats: PvolveSelectors.workouts.statsData(state),
    completed: PvolveSelectors.workouts.completedHistoryByDate(state),
}));

interface ThisWeekProps {}

const tinyCardStyles = { padding: 0, backgroundColor: 'transparent', boxShadow: 'none', border: 0 };

const ThisWeek = ({
    completed,
    workoutIndex,
    stats,
}: ThisWeekProps & ConnectedProps<typeof connector>) => {
    const isMobile = useBreakpoints(Breakpoints.sm, Directions.down);
    const isTablet = useBreakpoints(Breakpoints.lg, Directions.down);

    const daysContainerRef = useRef();

    if (!completed) {
        return null;
    }

    const parsedTodayString = parseDate(formatInternalDate(new Date()));
    const weekStartDate = startOfWeek(parsedTodayString, { weekStartsOn: 1 });
    const totalWorkouts = round(stats?.total_summary?.workouts_completed || 0);
    const totalWorkoutMins = stats?.total_summary?.total_minutes || 0;

    let totalHours: number | null = null;
    if (totalWorkoutMins > 59) {
        totalHours = round((totalWorkoutMins || 0) / 60);
    }

    const day = (dayNumber: number, index: number) => {
        const weekDay = addDays(weekStartDate, dayNumber);
        const scheduleItem = completed[formatInternalDate(weekDay)] || [];
        const completedWorkouts =
            scheduleItem?.length > 0
                ? scheduleItem
                    .map((historyItem) => workoutIndex[historyItem.workout_id])
                    .filter((item) => !!item)
                : [];

        const weekDayClasses = classNames(Styles.weekday, {
            [Styles.weekdayPassed]: isBefore(weekDay, parsedTodayString),
            [Styles.today]: isToday(weekDay),
            [Styles.completedCheck]: scheduleItem?.length > 0,
        });

        const _Circle = (
            <div key={`this-week-day-${index}`} className={weekDayClasses}>
                {isToday(weekDay) && scheduleItem?.length === 0 && (
                    <Icon className={Styles.dot} name="circle" />
                )}
                <p className={Styles.dayName}>{format(weekDay, 'EEEEEE')}</p>
                {scheduleItem?.length > 0 && (
                    <Icon
                        className={`${Styles.checkmark} display-flex align-self--center`}
                        size={90}
                        name="pv-checkmark"
                    />
                )}
            </div>
        );

        const tipContent = (
            <>
                {completedWorkouts.map(
                    (workout, _index: number) =>
                        _index <= 1 && (
                            <TinyListCard key={`card-${_index}`} workout_id={workout.sys.id} />
                        )
                )}

                {completedWorkouts.length > 2 && (
                    <LastCard
                        key="tiny-list-2"
                        to={`/workouts/completed/${format(weekDay, 'M-dd-yyyy')}`}
                        length={completedWorkouts.length}
                    />
                )}
            </>
        );

        const Circle = () => _Circle;
        let tipPosition: 'left' | 'right' | 'center' = 'center';
        let tipOffset: [number, number] = [0, 0];

        if (isMobile) {
            tipPosition = 'left';
        } else if (isTablet) {
            if (index < 2) {
                tipPosition = 'left';
                tipOffset = [5, 0];
            } else if (index > 4) {
                tipPosition = 'right';
                tipOffset = [-5, 0];
            }
        } else {
            if (index < 2) {
                tipPosition = 'left';
                tipOffset = [35, 0];
            } else if (index > 4) {
                tipPosition = 'right';
                tipOffset = [-35, 0];
            }
        }

        const position: StrictPopupProps['position'] = `bottom ${tipPosition}` as StrictPopupProps['position'];
        return (
            (completedWorkouts.length && (
                <Popup
                    key={`weekday-${index}`}
                    content={tipContent}
                    hoverable
                    flowing
                    context={isMobile ? daysContainerRef.current : undefined}
                    style={tinyCardStyles}
                    trigger={_Circle}
                    position={position}
                    offset={tipOffset}
                    className={`offset-${index}`}
                />
            )) || <Circle key={`weekday-${index}`} />
        );
    };

    const sectionStyles = classNames({
        [`${Styles.thisWeek}`]: true,
    });

    return (
        <div className={Styles.thisWeekWrapper}>
            <div className={sectionStyles}>
                <h2 className="stacked bold upper">This Week's</h2>
                <h1 className="stacked accent">activity</h1>
                <div ref={daysContainerRef} className={Styles.daysContainer}>
                    {times(7).map(day)}
                </div>
            </div>
            {!!stats && (
                <div className={Styles.statSection}>
                    <div className={Styles.allStatTitle}>
                        <h2 className="stacked bold upper">All time</h2>
                        <h1 className="stacked accent">stats</h1>
                    </div>
                    <div className={Styles.allStat}>
                        <div className={Styles.stat}>
                            <Icon className={Styles.iconBox} name="pv-fire-outline" size={40} />
                            <div>
                                <h1 className="stacked bold massive">{totalWorkouts}</h1>
                                <p className="p1 accent">workouts</p>
                            </div>
                        </div>
                        <div className={Styles.stat}>
                            <Icon className={Styles.iconBox} name="pv-clock-outline" size={40} />
                            <div>
                                {!!totalHours && (
                                    <>
                                        <h1 className="stacked bold ">{totalHours}</h1>
                                        <p className="p1 accent">hours</p>
                                    </>
                                )}

                                {totalWorkoutMins < 60 && (
                                    <>
                                        <h1 className="stacked bold ">{totalWorkoutMins}</h1>
                                        <p className="p1 accent">minutes</p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default connector(ThisWeek);
