import { IEnrollmentItem, IWorkoutHistoryItem } from '@pvolve/sdk/src/redux/selectors';
import { formatInternalDate, parseDate, INTERNAL_DATE_FORMAT } from '@pvolve/sdk/src/app/utils';
import { differenceInCalendarDays, add, format } from 'date-fns';
import {
    ButtonBack,
    ButtonNext,
    CarouselProvider,
    CarouselContext,
    Dot,
    Slide,
    Slider,
} from 'pure-react-carousel';
import React, {
    useMemo,
    useState,
    useEffect,
    useCallback,
    useRef,
    useContext,
    ReactFragment,
} from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Icon } from 'src/components/shared';
import * as Styles from 'src/styles/workout-schedule.module.scss';
import WorkoutScheduleSlide from './WorkoutScheduleSlide';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import Selectors from 'src/state/root-selectors';
import { IState } from '@pvolve/sdk/src/redux/selectors';
import { times } from 'lodash';
import { useBreakpoints, Directions, Breakpoints } from 'src/utils/breakpoint-utils';
import { getPastSlideData } from '../phase-and-function/phases/utils/enrolledUtils';

const connector = connect((state: IState) => ({
    indexedWorkouts: PvolveSelectors.content.resolveWorkout(state),
    historyByDate: PvolveSelectors.workouts.historyByDate(state),
    completedHistoryByDate: PvolveSelectors.workouts.completedHistoryByDate(state),
    featureflag: Selectors.flags.phaseAndFunction(state),
    phaseAttributes: PvolveSelectors.phases.currentPhases(state),
    pnfWorkoutsCarousel: PvolveSelectors.phases.pnfWorkoutsCarousel(state),
}));

interface WorkoutScheduleProps extends ConnectedProps<typeof connector> {
    currentSeries?: IEnrollmentItem;
    extraContentPath?: string;
}

interface CarouselContentProps {
    slideNav: ReactFragment;
    slideContainer: (value: number, index: number) => ReactFragment;
    setCurrentSlide: (index: number) => void;
    numItems: number;
    backgroundColor: string;
}

const CarouselContent = ({
    slideNav,
    setCurrentSlide,
    numItems,
    backgroundColor,
    slideContainer,
}: CarouselContentProps) => {
    const carouselContext = useContext(CarouselContext);

    useEffect(() => {
        const onChange = () => {
            setCurrentSlide(carouselContext.state.currentSlide);
        };

        carouselContext.subscribe(onChange);

        return () => carouselContext.unsubscribe(onChange);
    }, [carouselContext, setCurrentSlide]);

    return (
        <>
            {slideNav}
            <Slider>{times(numItems).map(slideContainer)}</Slider>
            <div className={Styles.backDrop} style={{ backgroundColor }} />
        </>
    );
};

const WorkoutScheduleCarousel = ({
    currentSeries,
    indexedWorkouts,
    historyByDate,
    completedHistoryByDate,
    extraContentPath,
    featureflag,
    phaseAttributes,
    pnfWorkoutsCarousel,
}: WorkoutScheduleProps) => {
    const [backgroundColor, setBackgroundColor] = useState('transparent');
    const isMobile = useBreakpoints(Breakpoints.sm, Directions.down);
    const pnfSeriesId = featureflag?.phaseAndFunctionSeries?.variant?.payload?.series ?? null;
    const isEnrolledToPnf =
        !!pnfSeriesId && pnfSeriesId.includes(currentSeries?.series_id) && !!phaseAttributes;
    const scheduledItems = useMemo(
        () =>
            isEnrolledToPnf
                ? pnfWorkoutsCarousel
                : Object.values(currentSeries?.series_state.workouts || {}).filter(
                    (historyItem) => historyItem.status !== 'workout_completed'
                ),
        [isEnrolledToPnf, currentSeries]
    );

    const startHistory = Object.keys(completedHistoryByDate)[0];
    const startDate = useMemo(
        () => (startHistory ? add(new Date(startHistory), { days: 1 }) : new Date()),
        [completedHistoryByDate]
    );
    const numScheduledItems = scheduledItems?.length;
    const todayKey = formatInternalDate(new Date());
    const parsedTodayString = parseDate(todayKey);

    const startIndex = startDate ? differenceInCalendarDays(parsedTodayString, startDate) : 0;

    const [currentSlide, setCurrentSlide] = useState(startIndex);
    const startDateAndIndex = startDate && startIndex > 0;
    const uncompletedWorkout = Object.values(historyByDate[todayKey] || []).find(function (
        historyItem
    ) {
        return !historyItem.is_completed;
    });

    let scheduleItems: { [k: string]: IWorkoutHistoryItem } = {};
    let scheduleIndex = 0;

    if (completedHistoryByDate[todayKey]) {
        scheduleIndex++;
    } else if (uncompletedWorkout) {
        scheduleItems[todayKey] = uncompletedWorkout;
        scheduleIndex++;
    } else if (numScheduledItems === 0) {
        scheduleIndex++;
    }

    for (let index = 0; index < numScheduledItems; index++) {
        const dayKey = format(
            add(new Date(parsedTodayString), { days: scheduleIndex }),
            INTERNAL_DATE_FORMAT
        );

        scheduleItems[dayKey] = scheduledItems[index];
        scheduleIndex++;
    }

    const myPlanItemByDay = scheduleItems;
    const numItems = startIndex + scheduleIndex;

    let lastCompletedIndex = -1;
    for (let index = currentSlide; index >= 0; index--) {
        const date = format(add(startDate, { days: index }), INTERNAL_DATE_FORMAT);
        if (completedHistoryByDate[date]) {
            lastCompletedIndex = index;
            break;
        }
    }

    const onClickBack = useCallback(() => {
        setCurrentSlide(currentSlide - 1 || 0);
    }, [currentSlide]);
    //- TODO CHECK PLUS/MINUS INDEX
    const onClickNext = useCallback(() => {
        setCurrentSlide(currentSlide > numItems - 1 ? numItems : currentSlide + 1);
    }, [currentSlide, numItems]);

    const goToToday = useCallback(() => {
        setCurrentSlide(startIndex);
    }, [startIndex]);

    const goToLastCompleted = useCallback(() => {
        setCurrentSlide(lastCompletedIndex);
    }, [lastCompletedIndex]);

    const sliderTray = useRef<HTMLElement | null>();
    useEffect(() => {
        sliderTray.current = document.querySelector('.carousel__slider-tray') as HTMLElement;
    }, []);

    useEffect(() => {
        setCurrentSlide(startIndex);
    }, [startIndex]);

    useEffect(() => {
        const contentTypeArray: number[] = [];
        const date = format(
            add(new Date(parsedTodayString), { days: currentSlide - startIndex }),
            INTERNAL_DATE_FORMAT
        );
        const scheduleItem = myPlanItemByDay[date];
        const isBackDropSlide = !scheduleItem?.is_completed ? 0 : 1;

        startDateAndIndex
            ? contentTypeArray.push(isBackDropSlide)
            : contentTypeArray.unshift(isBackDropSlide);

        if (!scheduleItem?.is_completed) {
            const workoutId = scheduleItem?.workout_id;
            const workout = workoutId ? indexedWorkouts(workoutId) : null;

            setBackgroundColor(workout?.workoutCategory?.color || 'transparent');
        } else {
            setBackgroundColor('transparent');
        }

        if (isMobile) {
            const newHeight = document.querySelectorAll('.carousel__inner-slide')[currentSlide]
                .clientHeight;

            if (sliderTray.current) {
                sliderTray.current.style.height = `${newHeight}px`;
            }
        }
    }, [
        myPlanItemByDay,
        currentSlide,
        indexedWorkouts,
        numItems,
        startDateAndIndex,
        startIndex,
        parsedTodayString,
        isMobile,
    ]);

    const slideContainer = (dayNumber: number, index: number) => {
        const date = add(new Date(parsedTodayString), { days: dayNumber - startIndex });
        const dateKey = format(date, INTERNAL_DATE_FORMAT);
        const currentWorkout = myPlanItemByDay[dateKey];

        // Phase and function cycle days calculation
        let pastSlideData = getPastSlideData(date, phaseAttributes);

        /* TODO: Refactor logic to only render a limited amount of slides per series, 
        BE can provide a small amount and then FE will ask for more when dragging when needed. 
        Explore the idea to use a different carousel component since this one doesn't support
        onDrag event to update the current slide value*/
        return (
            <Slide key={`slide-${index}`} index={index} className={Styles.slideElement}>
                <WorkoutScheduleSlide
                    active={currentSlide === index}
                    currentSeries={currentSeries}
                    currentWorkout={currentWorkout}
                    date={date}
                    lastCompletedIndex={lastCompletedIndex}
                    goToLastCompletedSlide={goToLastCompleted}
                    extraContentPath={extraContentPath}
                    pastSlideData={pastSlideData}
                />
            </Slide>
        );
    };

    if (!myPlanItemByDay) {
        return null;
    }

    const slideNav = numItems > 0 && (
        <div className="carousel-button-group-bottom">
            <ButtonBack onClick={onClickBack} className="carousel-button">
                <Icon name="pv-arrow-left" size={24} />
            </ButtonBack>
            <Dot className="carousel-button" slide={startIndex}>
                <Icon onClick={goToToday} name="circle" />
            </Dot>
            <ButtonNext onClick={onClickNext} className="carousel-button">
                <Icon name="pv-arrow-right" size={24} />
            </ButtonNext>
        </div>
    );

    const carouselContent = (
        <CarouselContent
            slideNav={slideNav}
            setCurrentSlide={setCurrentSlide}
            numItems={numItems}
            backgroundColor={backgroundColor}
            slideContainer={slideContainer}
        />
    );

    return (
        <div className={Styles.wrapper}>
            <div>
                <CarouselProvider
                    className={Styles.providerCarouselWrapper}
                    currentSlide={currentSlide}
                    naturalSlideWidth={320}
                    naturalSlideHeight={120}
                    totalSlides={numItems}
                    isIntrinsicHeight={!isMobile}
                >
                    {carouselContent}
                </CarouselProvider>
            </div>
        </div>
    );
};

export default connector(WorkoutScheduleCarousel);
