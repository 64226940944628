import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import Selectors from 'src/state/root-selectors';
import { ContentSelectors } from '@pvolve/sdk/src/redux/selectors';
import {
    QUESTIONNAIRE_CATEGORY,
    QUESTIONNAIRE_SUBCATEGORY,
} from '@pvolve/sdk/src/app/modules/questionnaire/constants';
import { IQuestionnaireAnswersState } from '@pvolve/sdk/src/redux/selectors';

export const useShouldRedirectToFeedback = () => {
    const currentSeries = useSelector(PvolveSelectors.series.enrollment.current);
    const questionnairesAnswers = useSelector(Selectors.account.questionnaireAnswersList);
    const series = useSelector(ContentSelectors.series.indexedBy.id);

    const currentSeriesData = series[currentSeries?.series_id];
    if (
        currentSeries?.percentage_completed === 1 &&
        !!currentSeriesData?.fields?.questionnaire &&
        currentSeriesData?.fields?.questionnaire?.fields?.category ===
            QUESTIONNAIRE_CATEGORY?.feedback &&
        currentSeriesData?.fields?.questionnaire?.fields?.subcategory ===
            QUESTIONNAIRE_SUBCATEGORY?.series
    ) {
        const feedbackLastData = questionnairesAnswers?.find(
            (questAnswers: IQuestionnaireAnswersState) =>
                questAnswers?.category === QUESTIONNAIRE_CATEGORY?.feedback &&
                questAnswers?.subcategory === QUESTIONNAIRE_SUBCATEGORY.series &&
                questAnswers?.series_id === currentSeries?.series_id
        );

        if (!!!feedbackLastData) {
            return true;
        }
    }
    return false;
};

export default useShouldRedirectToFeedback;
