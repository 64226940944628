// extracted by mini-css-extract-plugin
export const thisWeekWrapper = "this-week-module--this-week-wrapper--1eYmZ";
export const thisWeek = "this-week-module--this-week--2hsA1";
export const weekSection = "this-week-module--week-section--2Xn8B";
export const daysContainer = "this-week-module--daysContainer--1Xt9C";
export const dayName = "this-week-module--day-name--1-MkZ";
export const weekday = "this-week-module--weekday--2Jxwg";
export const complete = "this-week-module--complete--4_HGc";
export const incomplete = "this-week-module--incomplete--3vcuF";
export const today = "this-week-module--today--2cRGa";
export const dot = "this-week-module--dot--1DyWv";
export const weekdayPassed = "this-week-module--weekday-passed--39Pnb";
export const completedCheck = "this-week-module--completed-check--14gKE";
export const checkmark = "this-week-module--checkmark--1itaF";
export const statSection = "this-week-module--stat-section--h9mue";
export const allStatTitle = "this-week-module--all-stat-title--oWfu9";
export const allStat = "this-week-module--all-stat--qQShk";
export const stat = "this-week-module--stat--12mBU";
export const iconBox = "this-week-module--icon-box--3fBJk";