import React from 'react';
import { Button, Image } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import Selectors from 'src/state/root-selectors';
import { ModuleDataTypes } from 'src/state/promos/selectors';
import * as Styles from 'src/styles/promo-module-top.module.scss';
import { useSelector } from 'react-redux';

const PromoModules = () => {
    const data: ModuleDataTypes[] = useSelector((state) => Selectors.promos.topModule(state));
    const module = (module: ModuleDataTypes, index: number) => (
        <PromoModule key={`module-${index}`} {...module} />
    );

    return isEmpty(data) ? null : <>{data.map(module)}</>;
};

const PromoModule = ({
    headline1,
    headline2,
    body,
    link,
    image,
    cta,
    backgroundColor,
    color,
    ctaColor,
    ctaTextColor,
}: ModuleDataTypes) => {
    const contentStyles = {
        color,
        backgroundColor,
    };

    const ctaStyles = {
        backgroundColor: ctaColor,
        borderColor: ctaColor,
        color: ctaTextColor,
    };

    return (
        <div className={Styles.promoWrapper}>
            <div className={Styles.promoTop}>
                <div className={Styles.content} style={contentStyles}>
                    <p className={`${Styles.headline} upper margin-bottom--0`}>{headline1}</p>
                    <p className={`${Styles.headline} accent`}>{headline2}</p>
                    <p className={`${Styles.body} margin-bottom--0`}>{body}</p>
                    <Button as="a" href={link} style={ctaStyles}>
                        {cta}
                    </Button>
                </div>
                <Image src={image} className={Styles.image} verticalAlign="middle" centered />
            </div>
        </div>
    );
};

export default PromoModules;
