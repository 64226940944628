import { IState } from '@pvolve/sdk/src/redux/selectors';
import React, { useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { CardCarousel, WorkoutCard, Icon } from 'src/components/shared';
import * as workoutStateStyle from 'src/styles/workout-schedule.module.scss';
import * as Styles from 'src/styles/favorites-workouts.module.scss';
import Selectors from 'src/state/root-selectors';
import { IWorkout } from '@pvolve/sdk/src/models/contentful_types';
import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';

const connector = connect((state: IState) => ({
    favorites: Selectors.favorites.favoriteWorkouts(state),
}));

interface FavoritesWorkoutProps extends ConnectedProps<typeof connector> {}

interface WorkoutCardWithPlayerProps {
    data: IWorkout;
}

const FavoritesWorkout = ({ favorites }: FavoritesWorkoutProps) => {
    const WorkoutCardWithPlayer = useCallback(
        ({ data }: WorkoutCardWithPlayerProps) => <WorkoutCard workout={data} useBackgroundImage />,
        []
    );

    const favoritedWorkoutsCount = favorites?.length;
    const isFavoritesWorkouts = favoritedWorkoutsCount > 0;

    const isTabletLandscape = useBreakpoints(Breakpoints.lg, Directions.up);

    return (
        <div className={workoutStateStyle.favoritesSpacing}>
            {isFavoritesWorkouts ? (
                <CardCarousel
                    title="Favorites"
                    to="/favorites"
                    data={favorites}
                    renderItem={WorkoutCardWithPlayer}
                />
            ) : (
                <Grid>
                    <Grid.Row>
                        <div className={Styles.noFavoritesTop}>
                            <h3 className="bold upper margin-bottom--0">Favorites</h3>

                            <div>
                                <Icon size={24} name="pv-arrow-left" color="gray" />
                                <Icon
                                    size={24}
                                    color="gray"
                                    name="pv-arrow-right"
                                    className="margin-left--large"
                                />
                            </div>
                        </div>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column
                            className={Styles.noFavoritesHeader}
                            verticalAlign="middle"
                            width={3}
                        >
                            <h3 className="bold upper margin-bottom--0">Favorites</h3>
                        </Grid.Column>
                        <Grid.Column width={isTabletLandscape ? 13 : 16}>
                            <div className={Styles.wrapper}>
                                <div className={Styles.placeHolder}>
                                    <div className={Styles.noFavoritesCard}>
                                        <p>Love a workout?</p>
                                        <p className="bold accent margin--0">Press the heart</p>
                                        <p>and it'll appear here.</p>
                                    </div>
                                    <Icon className={Styles.favIcon} name="heart" />
                                </div>
                                {Array.from({ length: 10 }).map((_, index) => (
                                    <div key={index} className={Styles.placeHolder} />
                                ))}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </div>
    );
};

export default connector(FavoritesWorkout);
